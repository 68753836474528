import Pica from 'pica'

class CanvasUtil {
  async resizeImageAndGetData(
    image: HTMLImageElement,
    width: number,
    height: number,
    options: { sx: number; sy: number; sw: number; sh: number } = {
      sx: 0,
      sy: 0,
      sw: image.width,
      sh: image.height,
    }
  ): Promise<Uint8ClampedArray> {
    const canvas = await this.resizeImage(image, width, height, options)
    const ctx = canvas.getContext('2d', { willReadFrequently: true })
    if (!ctx) {
      throw new Error('Could not get context from canvas')
    }
    return ctx.getImageData(0, 0, width, height).data
  }

  async resizeImage(
    image: HTMLImageElement,
    width: number,
    height: number,
    options: { sx: number; sy: number; sw: number; sh: number }
  ): Promise<HTMLCanvasElement> {
    const { sx, sy, sw, sh } = options
    const fromCanvas = this.getImageCanvas(image, sx, sy, sw, sh)
    const toCanvas = this.createCanvas(width, height)

    const pica = new Pica({
      tile: 1024,
      concurrency: 1,
      features: ['js', 'wasm'], // Disable web workers & cib
      idle: 2000,
      createCanvas: this.createCanvas,
    })

    await pica.resize(fromCanvas, toCanvas)
    return toCanvas
  }

  createCanvas(width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    return canvas
  }

  getImageCanvas(
    image: HTMLImageElement,
    sx: number,
    sy: number,
    sw: number,
    sh: number
  ): HTMLCanvasElement {
    const canvas = document.createElement('canvas')

    canvas.width = image.naturalWidth || sw
    canvas.height = image.naturalHeight || sh

    const ctx = canvas.getContext('2d', { willReadFrequently: true })
    if (!ctx) {
      throw new Error('Could not get context from canvas')
    }
    if (sx !== undefined) {
      const tempCanvas = document.createElement('canvas')
      tempCanvas.width = image.width
      tempCanvas.height = image.height
      const sCtx = tempCanvas.getContext('2d')
      if (!sCtx) {
        throw new Error('Could not get context from temporary canvas')
      }
      sCtx.drawImage(image, 0, 0)
      const imageData = sCtx.getImageData(sx, sy, sw, sh)
      ctx.putImageData(imageData, 0, 0)
    } else {
      ctx.drawImage(image, 0, 0)
    }

    return canvas
  }
}

export const canvasUtil = new CanvasUtil()

class GrayScaleConverter {
  // Constants for grayscale conversion
  private static readonly RED_WEIGHT = 299;
  private static readonly GREEN_WEIGHT = 587;
  private static readonly BLUE_WEIGHT = 114;
  private static readonly SCALE_FACTOR = 1000;

  // RGBA -> L (ITU-R 601-2 luma transform)
  convert(imgData: Uint8ClampedArray) {
    const arr = new Uint8ClampedArray(imgData.length / 4);

    for (let i = 0; i < imgData.length; i += 4) {
      arr[i >> 2] = Math.round(
        (imgData[i] * GrayScaleConverter.RED_WEIGHT +
          imgData[i + 1] * GrayScaleConverter.GREEN_WEIGHT +
          imgData[i + 2] * GrayScaleConverter.BLUE_WEIGHT) /
          GrayScaleConverter.SCALE_FACTOR
      );
    }
    return arr;
  }
}

export const grayScaleConverter = new GrayScaleConverter()
