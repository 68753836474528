import { useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import ProductPage from './product-page/ProductPage'
import PreviewPage from './Preview'
import ApiClient from '../../ApiClient'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import usePrintify from './hooks/usePrintify'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import LogsPanel from './LogsPanel'
import {
  AddItemToCartPayload,
  addItemToCartPayloadSchema,
  CartItem,
} from '../../clients/fagl-server/types/photoProductsCart'
import { Variant, Blueprint } from '../../clients/fagl-server/types/printify'
import useCuratedPhotos from './hooks/useCuratedPhotos'
import { DesignTemplate } from '../../clients/fagl-server/types'
import SelectTemplatePage from './select-template'
import EditTemplatePage from './edit-template'
import SinglePhotoCreateFlow from './SinglePhotoCreateFlow'
import LoadingOverlay from './components/LoadingOverlay'

export default function CreateFlow({
  api,
  editedCartItem,
  openTermsOfUsePage,
  navigateBackToSource,
  isPrintifyPreviewLogsActive,
  addItemToCart,
  cancelCartItemEdit,
  product,
  variants,
  currency = 'USD',
  productImages,
  carouselImages,
  numberOfItemsInCart,
  onShoppingCartClick,
}: {
  api: ApiClient
  editedCartItem: CartItem | null
  openTermsOfUsePage: () => void
  navigateBackToSource: () => void
  isPrintifyPreviewLogsActive: boolean
  addItemToCart: (payload: AddItemToCartPayload) => void
  cancelCartItemEdit: () => void
  product: Blueprint
  variants: Variant[]
  currency: string
  productImages: string[]
  carouselImages: string[]
  numberOfItemsInCart: number
  onShoppingCartClick: () => void
}) {
  const defaultVariantId = variants[0].variantId

  const {
    generatePreview,
    updateQuantityAndVariant,
    previewPhotosList,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
    sourceImagesDataUrls,
    createPayloadWithVariant,
    resetState,
    variantId,
    quantity,
    isPreparingEdit,
  } = usePrintify({
    blueprintId: product.blueprintId,
    defaultVariantId,
    editedCartItem,
  })

  const isEditMode = !!editedCartItem
  const { curatedPhotos, arePhotosLoaded, getCuratedPhotos, isUsingFallback } = useCuratedPhotos()
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    editedCartItem?.metadata.designTemplateId ?? null
  )

  const photoUrls = useMemo(() => {
    if (sourceImagesDataUrls.length > 0) {
      return sourceImagesDataUrls
    }

    return curatedPhotos.map((photo) => ({ original: photo.url, cropped: photo.url }))
  }, [curatedPhotos, sourceImagesDataUrls])

  const selectedTemplate = useMemo(() => {
    return product.designTemplates.find((dt) => dt.id === selectedTemplateId)
  }, [product.designTemplates, selectedTemplateId])

  const navigate = useNavigate()
  const variantsById: Record<number, Variant> = useMemo(
    () => Object.fromEntries(variants.map((v) => [v.variantId, v])),
    [variants]
  )

  const variant = variantsById[variantId]

  const priceFormatter = useMemo(() => create2DecimalsCurrencyFormatter(currency), [currency])

  const formattedPrices = useMemo(() => {
    const zeroPrice = `${priceFormatter(0)} ${currency}`
    return {
      price: variant?.price ? `${priceFormatter(variant.price / 100)} ${currency}` : zeroPrice,
      salePrice: variant?.salePrice
        ? `${priceFormatter(variant.salePrice / 100)} ${currency}`
        : zeroPrice,
    }
  }, [priceFormatter, currency])

  const {
    photoProducts: {
      printify: { recordDidTapPersonalize, recordDidTapPreview },
    },
  } = useRecordUserAction(api)

  const [isAddingItemToCart, setIsAddingItemToCart] = useState(false)
  const [isLogsPageVisible, setIsLogsPageVisible] = useState(false)

  const navigateToPayment = useCallback(
    (path: string = '') => {
      navigate(`payment${path}`)
    },
    [navigate]
  )

  const onTemplateSelected = useCallback(
    (template: DesignTemplate) => {
      setSelectedTemplateId(template.id)
      navigate('edit')
    },
    [navigate]
  )

  const onPersonalizeCompleted = useCallback(
    async (
      variantId: number,
      productImageDataUrl: string,
      sourceImageDataUrls: {
        original: string
        cropped: string
      }[]
    ) => {
      recordDidTapPreview({
        blueprintId: product.blueprintId,
      })

      navigate('preview')
      const payload = {
        variant: variantsById[variantId],
        productImageDataUrl,
        sourceImageDataUrls,
      }

      await generatePreview(payload)
    },
    [
      generatePreview,
      navigate,
      recordDidTapPreview,
      product.blueprintId,
      navigateToPayment,
      variantsById,
    ]
  )

  const onPreviewCompleted = useCallback(async () => {
    setIsAddingItemToCart(true)
    const payload = await createPayloadWithVariant(variant)
    const result = addItemToCartPayloadSchema.safeParse({
      ...payload,
      metadata: {
        ...payload.metadata,
        designTemplateId: selectedTemplate?.id,
      },
    })

    if (result.success) {
      await addItemToCart(result.data)
      resetState()
      setIsAddingItemToCart(false)
    } else {
      console.error(result.error)
    }
  }, [product.blueprintId, createPayloadWithVariant, resetState, variant])

  const onProductSelectionCompleted = useCallback(
    (payload: { quantity: number; variantId: number }) => {
      updateQuantityAndVariant(payload.quantity, payload.variantId)
      recordDidTapPersonalize({
        blueprintId: product.blueprintId,
      })

      if (product.hasDesignTemplates) {
        navigate('select-template')
      } else {
        navigate('edit')
      }
    },
    [
      navigate,
      updateQuantityAndVariant,
      recordDidTapPersonalize,
      product.blueprintId,
      product.hasDesignTemplates,
    ]
  )

  useEffect(() => {
    getCuratedPhotos()
  }, [getCuratedPhotos])

  if (isPreparingEdit) {
    return <LoadingOverlay />
  }

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <ProductPage
              carouselImages={carouselImages}
              productImages={productImages}
              openTermsOfUsePage={openTermsOfUsePage}
              price={formattedPrices.price}
              salePrice={formattedPrices.salePrice}
              initialQuantity={quantity}
              product={product}
              variantTitle={variant.title}
              variants={variants}
              initialVariantId={variantId}
              next={onProductSelectionCompleted}
              previous={navigateBackToSource}
              updateQuantityAndVariant={updateQuantityAndVariant}
            />
          }
        />
        <Route
          path="select-template"
          element={
            <SelectTemplatePage
              previous={() => navigate('.')}
              templateWidth={variant.width}
              templateHeight={variant.height}
              curatedPhotos={curatedPhotos}
              arePhotosLoaded={arePhotosLoaded}
              selectTemplate={onTemplateSelected}
              designTemplates={product.designTemplates}
              numberOfItemsInCart={numberOfItemsInCart}
              onShoppingCartClick={onShoppingCartClick}
            />
          }
        />

        <Route
          path="edit"
          element={
            selectedTemplate && product.hasDesignTemplates ? (
              <EditTemplatePage
                templateWidth={variant.width}
                templateHeight={variant.height}
                template={selectedTemplate}
                sourceImages={isUsingFallback && !sourceImagesDataUrls.length ? [] : photoUrls}
                previous={() => navigate('select-template')}
                next={(dataUrl, sourceImageDataUrls) =>
                  onPersonalizeCompleted(variantId, dataUrl, sourceImageDataUrls)
                }
              />
            ) : (
              <SinglePhotoCreateFlow
                isPreparingEdit={isPreparingEdit}
                variant={variant}
                sourceImages={sourceImagesDataUrls}
                previous={() => navigate('.')}
                editedCartItem={editedCartItem}
                cancelCartItemEdit={cancelCartItemEdit}
                product={product}
                variants={variants}
                currency={currency}
                next={(dataUrl, sourceImageDataUrls) =>
                  onPersonalizeCompleted(variantId, dataUrl, sourceImageDataUrls)
                }
              />
            )
          }
        />
        <Route
          path="preview"
          element={
            <PreviewPage
              isGettingPreview={isGettingPreview}
              isAddingItemToCart={isAddingItemToCart}
              isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
              openLogsPage={() => setIsLogsPageVisible(true)}
              showNotAllLoadedError={showNotAllLoadedError}
              previewPhotosList={previewPhotosList}
              previous={() => navigate('edit')}
              next={onPreviewCompleted}
              regeneratePreview={regeneratePreview}
            />
          }
        />
      </Routes>
      {isPrintifyPreviewLogsActive && (
        <LogsPanel
          logs={fetchLogs}
          isOpen={isLogsPageVisible}
          close={() => setIsLogsPageVisible(false)}
        />
      )}
    </>
  )
}
