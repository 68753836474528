import { MouseEventHandler, useCallback, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import usePhotoGifts from '../hooks/usePhotoGifts'
import useRecordUserAction, { C2AMetadata } from '../hooks/useRecordUserAction'
import PhotoGiftsSection from '../pages/grandparents-day/components/PhotoGiftsSection'
import ShoppingCartBadge from '../pages/photo-products/components/ShoppingCartBadge'
import { useApi } from '../hooks/useApi'

export default function PhotoGiftsProductList({
  printifyProductsListLink,
  numberOfItemsInCart,
  onShoppingCartClick,
}: {
  printifyProductsListLink: string
  numberOfItemsInCart: number
  onShoppingCartClick: MouseEventHandler<HTMLAnchorElement>
}) {
  const { api } = useApi()
  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')

  const photoGifts = usePhotoGifts(printifyProductsListLink, uuid)
  const photoProductItems = photoGifts.getPhotoProductItems()

  const {
    photoProducts: { printify, divvyup },
  } = useRecordUserAction(api)

  const onProductClick = useCallback(
    (metadata: C2AMetadata) => {
      window.scrollTo(0, 0)
      if (metadata.blueprintId) {
        printify.recordDidSelectPhotoProduct(metadata)
      } else {
        divvyup.recordDidSelectPhotoProduct(metadata)
      }
    },
    [printify, divvyup]
  )

  return (
    <div className="max-w-lg mx-auto">
      <header className="p-4 text-black">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">Select a Photo Gift</h2>
          <Link to="/photo-products/cart" onClick={onShoppingCartClick}>
            <ShoppingCartBadge numberOfItemsInCart={numberOfItemsInCart} />
          </Link>
        </div>
      </header>
      <PhotoGiftsSection onProductClick={onProductClick} photoProductItems={photoProductItems} />
    </div>
  )
}
