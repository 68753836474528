export default function ProductPageHeader({
  variantTitle,
  price,
  salePrice,
}: {
  variantTitle: string
  price: string
  salePrice: string
}) {
  return (
    <header className="space-y-1">
      <h1 className="font-bold text-xl">{variantTitle}</h1>
      <div className="flex space-x-1 items-end uppercase">
        <span className="line-through">{price}</span>
        <span className="text-tomato-9">{salePrice}</span>
      </div>
      <p className="text-s text-gray-11">(Local taxes included)</p>
    </header>
  )
}
