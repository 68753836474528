import { useCallback, useState } from 'react'
import { Blueprint } from '../../../clients/fagl-server/types/printify'
import { useApi } from '../../../hooks/useApi'
import { isAxiosError } from 'axios'
import { useRollbar } from '@rollbar/react'

const NUMBER_OF_UNIQUE_TEMPLATE_SECTION_IMAGES = 6
const NUMBER_OF_PHOTO_PRODUCT_MAIN_GALLERY_IMAGES = 4

function createMainGalleryPhotoUrlsForProductId(blueprintId: number, number: number) {
  const urls = []
  for (let i = 1; i <= number; i++) {
    urls.push(`/photo-products/${blueprintId}_${i}.jpg`)
  }
  return urls
}

function createUniqueTemplatePhotoUrlsForProductId(blueprintId: number, number: number) {
  const urls = []
  for (let i = 1; i <= number; i++) {
    urls.push(`/design-template-carousels/${blueprintId}/${i}.png`)
  }
  return urls
}

export function useProduct() {
  const { api } = useApi()
  const rollbar = useRollbar()
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState<Blueprint | null>(null)
  const [productImages, setProductImages] = useState<string[]>([])
  const [carouselImages, setCarouselImages] = useState<string[]>([])
  const [loadError, setLoadError] = useState<string>('')
  const variants = product?.variants || []

  const load = useCallback(
    async (id: number) => {
      setLoadError('')
      try {
        const blueprint = await api.printify.getBlueprintAndVariants(id)
        setProduct(blueprint)
        setProductImages(
          createMainGalleryPhotoUrlsForProductId(id, NUMBER_OF_PHOTO_PRODUCT_MAIN_GALLERY_IMAGES)
        )
        setCarouselImages(
          createUniqueTemplatePhotoUrlsForProductId(id, NUMBER_OF_UNIQUE_TEMPLATE_SECTION_IMAGES)
        )
      } catch (err) {
        const error = err as Error
        if (isAxiosError(err)) {
          if (err.response?.status === 404) {
            setLoadError('NOT_FOUND')
          }
        } else {
          console.error(err)
          rollbar.error('unexpected error loading Printify blueprint', error)
          setLoadError(error.message)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [product, setProduct]
  )

  return {
    isLoading,
    product,
    loadError,
    load,
    variants,
    productImages,
    carouselImages,
  }
}
