import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useRollbar } from '@rollbar/react'
import { Variant } from '../../../clients/fagl-server/types/printify'
import useRecordUserAction from '../../../hooks/useRecordUserAction'
import { CartItem, Provider } from '../../../clients/fagl-server/types/photoProductsCart'
import { useApi } from '../../../hooks/useApi'
import { chunk } from 'lodash'
import { usePreview, PreviewImage } from './usePreview'

export default function usePrintify({
  blueprintId,
  defaultVariantId,
  editedCartItem,
}: {
  blueprintId: number
  defaultVariantId: number
  editedCartItem: CartItem | null
}) {
  const rollbar = useRollbar()
  const { api } = useApi()
  const {
    photoProducts: {
      printify: { recordPreviewSuccess, recordPreviewFailure },
    },
  } = useRecordUserAction(api)

  const [variantId, setVariantId] = useState<number>(
    editedCartItem?.metadata.variantId || defaultVariantId
  )

  const [showNotAllLoadedError, setShowNotAllLoadedError] = useState(true)
  const [isGettingPreview, setIsGettingPreview] = useState(false)
  const [isPreparingEdit, setIsPreparingEdit] = useState(false)
  const [isGettingPreviewComplete, setIsGettingPreviewComplete] = useState(false)

  const [sourceImagesDataUrls, setSourceImagesDataUrls] = useState<
    {
      cropped: string
      original: string
    }[]
  >([])

  const [sourceImages, setSourceImages] = useState<{ cropped: string; original: string }[]>(
    editedCartItem?.metadata.sourceImages ?? []
  )

  const [productImageDataUrl, setProductImageDataUrl] = useState<string | null>(null)

  const [itemIdentifier, setItemIdentifier] = useState<string | null>(
    editedCartItem?.itemIdentifier ?? null
  )
  const [quantity, setQuantity] = useState(editedCartItem?.quantity ?? 1)
  const { previewUrlState, setPreviewUrlState, fetchPreviewImages } = usePreview(api, variantId)
  const [fetchLogs, setFetchLogs] = useState<{ [key: string]: string[] }>({})

  const resetState = useCallback(() => {
    setPreviewUrlState({})
    setFetchLogs({})
    setIsGettingPreviewComplete(false)
  }, [])

  const previewPhotosList = Object.values(previewUrlState)

  const uploadSourceImages = useCallback(
    async (
      imageUrls: {
        original: string
        cropped: string
      }[]
    ) => {
      try {
        setSourceImagesDataUrls(imageUrls)
        const result = await Promise.all(
          imageUrls.map(async (image) => {
            const [cropped, original] = await Promise.all([
              api.uploadBase64Image({
                base64: image.cropped,
                name: 'cropped',
              }),
              api.uploadBase64Image({
                base64: image.original,
                name: 'original',
              }),
            ])
            return {
              cropped: cropped.url,
              original: original.url,
            }
          })
        )
        setSourceImages(result)

        return result
      } catch (err) {
        rollbar.error('Error uploading used images', err as Error)
        console.error(err)
        return []
      }
    },
    []
  )

  const generatePreview = useCallback(
    async ({
      variant,
      productImageDataUrl: newProductImageDataUrl,
      sourceImageDataUrls,
    }: {
      variant: Variant
      productImageDataUrl: string
      sourceImageDataUrls: {
        original: string
        cropped: string
      }[]
    }) => {
      // Convert the source photo data URLs to a CDN URLs and save to state.
      // When adding a cart item, we need those URLs to be stored in the cart item metadata
      // so we can restore them when editing the item
      uploadSourceImages(sourceImageDataUrls)

      setVariantId(variant.variantId)

      // If the product image data URL hasn't changed and the variant ID hasn't changed,
      // we don't need to regenerate the preview
      if (productImageDataUrl === newProductImageDataUrl && variant.variantId === variantId) {
        return
      }

      setIsGettingPreview(true)
      setIsGettingPreviewComplete(false)
      setPreviewUrlState({})
      setProductImageDataUrl(newProductImageDataUrl)

      let imageUrl = ''

      try {
        const { url } = await api.uploadBase64Image({
          base64: newProductImageDataUrl,
          name: 'final',
        })
        imageUrl = url
      } catch (err) {
        toast.error('Something went wrong. Please try again later.')
        rollbar.error('Error uploading base 64 image', err as Error)
        console.error(err)
      }

      if (!imageUrl) {
        return
      }

      try {
        const { images, productId } = await api.printify.getImagesForPreview({
          blueprintId,
          variantId,
          imageUrl,
          providerId: variant.providerId,
        })

        setItemIdentifier(productId)

        await getPreview(images.map((image) => ({ ...image, initialBase64: '' })))
      } catch (err) {
        toast.error('Something went wrong. Please try again later.')
        rollbar.error('Error creating Printify order for preview purposes', err as Error)
        console.error(err)
      } finally {
        setIsGettingPreview(false)
      }
    },
    [blueprintId, productImageDataUrl, api, rollbar, quantity]
  )

  const regeneratePreview = useCallback(async () => {
    setShowNotAllLoadedError(false)

    const payloadForGetPreviewForNotReadyPhotos = previewPhotosList.map((preview) => ({
      src: preview.url,
      is_default: preview.isDefault,
      initialBase64: '',
    }))

    getPreview(payloadForGetPreviewForNotReadyPhotos)
  }, [previewPhotosList])

  async function convertCdnPhotosToBase64(
    sourceImage: {
      original: string
      cropped: string
    }[]
  ) {
    setIsPreparingEdit(true)
    const response = await api.convertCdnPhotosToBase64(
      sourceImage.flatMap((image) => [
        { url: image.original, uuid: new Date().getTime().toString() },
        { url: image.cropped, uuid: new Date().getTime().toString() },
      ])
    )

    const convertedImages = chunk(response, 2).map(([original, cropped]) => ({
      original: original.url,
      cropped: cropped.url,
    }))

    setSourceImagesDataUrls(convertedImages)
    setIsPreparingEdit(false)
  }

  const updateQuantityAndVariant = useCallback(
    (quantity: number, variantId: number) => {
      setQuantity(quantity)
      setVariantId(variantId)
    },
    [setQuantity, setVariantId]
  )

  const previewStatus = useMemo(() => {
    if (previewPhotosList.length === 0) {
      return { logPayload: { numberOfPhotos: 0 }, status: 'PENDING' }
    }

    const readyPreviews = previewPhotosList.filter(
      (preview) => preview.isPreviewReady && preview.base64
    )

    const areAllPreviewsReady = readyPreviews.length === previewPhotosList.length
    const areAllDoneTrying = previewPhotosList.every((preview) => preview.isDoneTrying)

    if (!areAllDoneTrying) {
      return {
        status: 'PENDING',
        logPayload: {
          numberOfPhotos: previewPhotosList.length,
          numberOfPhotosReady: readyPreviews.length,
        },
      }
    }

    const areZeroPreviewsReady = readyPreviews.length === 0
    const possiblySucceededOnFirstLoad = previewPhotosList.some(
      (preview) => preview.possiblySucceededOnFirstLoad
    )

    const logPayload = {
      numberOfPhotos: previewPhotosList.length,
      numberOfPhotosReady: readyPreviews.length,
      possiblySucceededOnFirstLoad,
    }
    if (areAllPreviewsReady || (areZeroPreviewsReady && possiblySucceededOnFirstLoad)) {
      return { status: 'SUCCESS', logPayload }
    } else {
      return { status: 'FAILURE', logPayload }
    }
  }, [previewPhotosList])

  useEffect(() => {
    const { status, logPayload } = previewStatus
    if (status !== 'PENDING' && !isGettingPreviewComplete) {
      if (status === 'SUCCESS') {
        recordPreviewSuccess(logPayload)
      } else if (status === 'FAILURE') {
        setShowNotAllLoadedError(true)
        recordPreviewFailure(logPayload)
        rollbar.warning('Printify previews not ready', logPayload)
      }
      setIsGettingPreviewComplete(true)
    }
  }, [previewStatus, isGettingPreviewComplete])

  const previewCdnUrl =
    previewPhotosList.find((photo) => photo.isDefault)?.url || previewPhotosList[0]?.url

  const createPayloadWithVariant = useCallback(
    (selectedVariant: Variant) => {
      return {
        itemIdentifier,
        provider: Provider.PRINTIFY,
        metadata: {
          blueprintId,
          previewCdnUrl,
          sourceImages,
          printProviderId: selectedVariant.providerId,
          variantId: selectedVariant.variantId,
          price: selectedVariant.price,
          title: selectedVariant.title,
        },
      }
    },
    [blueprintId, previewCdnUrl, sourceImages, itemIdentifier]
  )

  // Convert the source images to base64 so we can use them in the personalize screen
  useEffect(() => {
    if (editedCartItem) {
      convertCdnPhotosToBase64(editedCartItem.metadata.sourceImages)
    }
  }, [editedCartItem])

  const getPreview = useCallback(
    async (images: PreviewImage[]) => {
      setIsGettingPreview(true)
      setShowNotAllLoadedError(false)

      await fetchPreviewImages(images)

      setIsGettingPreview(false)
    },
    [setIsGettingPreview, setShowNotAllLoadedError, fetchPreviewImages]
  )

  return {
    quantity,
    setQuantity,
    blueprintId,
    generatePreview,
    previewPhotosList,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
    updateQuantityAndVariant,
    createPayloadWithVariant,
    variantId,
    resetState,
    sourceImages,
    sourceImagesDataUrls,
    isPreparingEdit,
  }
}
