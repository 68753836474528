import PhotoSelector from './components/PhotoSelector'
import { useCallback, useState } from 'react'
import CropIcon from '../../assets/crop.svg?react'
import Button from '../../shared/buttons/Button'
import CropDialog from './components/CropDialog'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import { Variant } from '../../clients/fagl-server/types/printify'
import VariantSelectionSection from './components/VariantSelectionSection'

export default function PersonalizePage({
  isEditMode,
  closeCropDialog,
  openCropDialog,
  confirmCrop,
  previous,
  next,
  aspectRatio,
  isCropping,
  onNewPhotoSelected,
  cancelCartItemEdit,
  urls,
  initialVariantId,
  variants,
  isPreparingEdit,
}: {
  isEditMode: boolean
  closeCropDialog: () => void
  openCropDialog: () => void
  confirmCrop: (dataUrl: string) => void
  next: (variantId: number) => void
  previous: () => void
  cancelCartItemEdit: () => void
  isCropping: boolean
  aspectRatio: number
  onNewPhotoSelected: (base64: string[]) => void
  urls: {
    original: string | null
    cropped: string | null
  }
  initialVariantId: number
  variants: Variant[]
  isPreparingEdit: boolean
}) {
  const [isBackWarningVisible, setIsBackWarningVisible] = useState(false)
  const [variantId, setVariantId] = useState<number>(initialVariantId)
  const onNext = useCallback(() => {
    if (variantId) {
      next(variantId)
    }
  }, [next, variantId, initialVariantId])

  const onPrevious = useCallback(() => {
    if (isEditMode) {
      setIsBackWarningVisible(true)
    } else {
      previous()
    }
  }, [previous, isEditMode])

  const onBackWarningConfirm = useCallback(() => {
    setIsBackWarningVisible(false)
    cancelCartItemEdit()
  }, [cancelCartItemEdit])

  const onBackWarningCancel = useCallback(() => {
    setIsBackWarningVisible(false)
  }, [])

  return (
    <>
      <div className="h-screen bg-gray-2 relative">
        <div className="px-4 pb-8 space-y-8 flex flex-col mb-auto">
          <header className="pt-8">
            <h1 className="font-bold text-xl">Personalize your design</h1>
          </header>
          <section>
            <img
              src={urls.cropped || urls.original || ''}
              className="max-w-full max-h-60 mx-auto"
            />
            <Button
              isLoading={isPreparingEdit}
              colorVariant="primary:invert:no-border:transparent"
              disabled={isEditMode && isPreparingEdit}
              onClick={openCropDialog}
              className="flex items-center font-bold text-s justify-center w-full"
            >
              <CropIcon className="w-6 mr-2" />
              Crop
            </Button>
          </section>
          <section className="space-y-2">
            <h2 className="font-bold">Select a different photo</h2>
            <PhotoSelector numberOfPhotos={1} onPhotoSelected={onNewPhotoSelected} />
          </section>
          {variantId && (
            <VariantSelectionSection
              variants={variants}
              selectedVariantId={variantId}
              updateVariantId={setVariantId}
            />
          )}
        </div>
        <footer className="text-center space-y-4 p-2 bg-white sticky w-full top-full">
          <div className="grid grid-cols-2 gap-2">
            <Button onClick={onPrevious} colorVariant="primary:invert">
              Back
            </Button>
            <Button onClick={onNext} colorVariant="primary">
              Preview
            </Button>
          </div>
        </footer>
      </div>
      <ConfirmationDialog
        isOpen={isBackWarningVisible}
        confirm={onBackWarningConfirm}
        close={onBackWarningCancel}
        title="Are you sure you want to go back?"
        message="You will lose all your changes."
      />
      <CropDialog
        isOpen={isCropping}
        url={urls.original}
        aspect={aspectRatio}
        confirm={confirmCrop}
        close={closeCropDialog}
      />
    </>
  )
}
