import Collapsible from '../../../shared/Collapsible'

export default function ProductPageDetails({
  itemDetails,
  keyFeatures,
}: {
  itemDetails: string
  keyFeatures: string[]
}) {
  return (
    <section>
      <Collapsible header="Item details">{itemDetails}</Collapsible>
      <Collapsible header="Key features">
        {keyFeatures.map((feature) => (
          <p className="mb-4" key={feature}>
            {feature}
          </p>
        ))}
      </Collapsible>
      <Collapsible header="Delivery information">
        Shipping charges and times vary by delivery location. For exact details please enter your
        shipping address on the payment screen.
      </Collapsible>
    </section>
  )
}
