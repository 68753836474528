import { Fragment, MouseEventHandler, useRef } from 'react'
import { groupBy } from 'lodash'
import { format, parseISO } from 'date-fns'
import Button from '../../../shared/buttons/Button'
import { MediaFile } from '../../../ApiClient'
import Header from './Header'
import GroupHeader from './GroupHeader'
import PhotoTile from './PhotoTile'

export default function Album({
  onClose,
  photos,
  title,
  onBack,
  albumName,
  selectedPhotos,
  confirmAdd,
  togglePhotoSelection,
  loadMore,
  hasNext,
  isSingleSelectionMode,
  isLoadingFamilyPhotos,
}: {
  photos: MediaFile[]
  onClose: Function
  onBack: Function
  albumName: string
  selectedPhotos: string[]
  title: string
  confirmAdd: MouseEventHandler
  togglePhotoSelection: (uuid: string) => void
  loadMore: Function
  hasNext: boolean
  isSingleSelectionMode: boolean
  isLoadingFamilyPhotos: boolean
}) {
  const byMonthYear = groupBy(photos, (photo) => {
    const date = parseISO(photo.tookAt)
    return `${format(date, 'MMMM yyyy')}`
  })
  const ref = useRef<HTMLDivElement>(null)

  const onScroll = () => {
    if (hasNext) {
      const target = ref.current
      if (target) {
        const isBottom = target.scrollHeight - target.scrollTop === target.clientHeight

        if (isBottom) {
          loadMore()
        }
      }
    }
  }

  return (
    <div className="flex flex-col h-full">
      <Header onBack={() => onBack()} onClose={() => onClose(false)} title={title} />
      <GroupHeader>{albumName}</GroupHeader>
      <div ref={ref} onScroll={onScroll} className="overflow-auto flex-1" data-clarity-mask="True">
        {Object.keys(byMonthYear).map((monthYearKey) => (
          <Fragment key={monthYearKey}>
            <div className="text-s py-2 px-4 bg-white text-gray-12">{monthYearKey}</div>
            <div
              className="grid grid-cols-3 grid-rows-photos gap-[1px] bg-white"
              data-clarity-mask="True"
            >
              {byMonthYear[monthYearKey].map((photo) => (
                <PhotoTile
                  isSingleSelectionMode={isSingleSelectionMode}
                  selectionIndex={selectedPhotos.indexOf(photo.uuid)}
                  key={photo.uuid}
                  togglePhotoSelection={togglePhotoSelection}
                  uuid={photo.uuid}
                  url={photo.url}
                  isSelected={selectedPhotos.includes(photo.uuid)}
                />
              ))}
            </div>
          </Fragment>
        ))}
      </div>
      <footer className="text-center space-y-4 p-2 bg-white sticky bottom-0">
        <div className="grid grid-cols-2 gap-2">
          <Button colorVariant="primary:invert" onClick={() => onClose(false)}>
            Back
          </Button>
          <Button
            colorVariant="primary"
            disabled={selectedPhotos.length === 0}
            onClick={confirmAdd}
          >
            Create
          </Button>
        </div>
      </footer>
    </div>
  )
}
