import { useCallback } from 'react'
import PersonalizePage from './Personalize'
import { CartItem } from '../../clients/fagl-server/types/photoProductsCart'
import useCrop from './hooks/useCrop'
import { Variant, Blueprint } from '../../clients/fagl-server/types/printify'
import SelectPhotoPage from './SelectPhoto'

export default function SinglePhotoCreateFlow({
  editedCartItem,
  cancelCartItemEdit,
  product,
  variants,
  next,
  previous,
  variant,
  sourceImages,
  isPreparingEdit,
}: {
  editedCartItem: CartItem | null
  cancelCartItemEdit: () => void
  product: Blueprint
  variants: Variant[]
  currency: string
  next: (
    dataUrl: string,
    sourceImageDataUrls: {
      original: string
      cropped: string
    }[]
  ) => void
  previous: () => void
  isPreparingEdit: boolean
  variant: Variant
  sourceImages: {
    original: string
    cropped: string
  }[]
}) {
  const isEditMode = !!editedCartItem

  const { isCropping, initCrop, resetCrop, fullReset, confirmCrop, closeCropDialog, urls } =
    useCrop(
      sourceImages[0]
        ? {
            original: sourceImages[0]?.original ?? null,
            cropped: sourceImages[0]?.cropped ?? null,
          }
        : null
    )

  const onPhotoSelected = useCallback((base64: string[]) => {
    initCrop(base64[0])
  }, [])

  const onCropConfirmed = useCallback(
    (base64: string) => {
      confirmCrop(base64)
    },
    [confirmCrop]
  )

  const onNext = useCallback(async () => {
    if (!urls.cropped) {
      return
    }

    const payload = {
      productImageDataUrl: urls.cropped,
      sourceImageDataUrls: [
        {
          original: urls.original ?? '',
          cropped: urls.cropped ?? '',
        },
      ],
    }

    return next(urls.cropped, payload.sourceImageDataUrls)
  }, [product.blueprintId, urls.cropped])

  const onNewPhotoSelected = useCallback(
    (dataUrls: string[]) => {
      resetCrop(dataUrls[0])
    },
    [resetCrop]
  )

  if (!urls.original) {
    return <SelectPhotoPage next={onPhotoSelected} previous={previous} />
  }

  return (
    <PersonalizePage
      key={variant.variantId}
      isPreparingEdit={isPreparingEdit}
      isEditMode={isEditMode}
      cancelCartItemEdit={cancelCartItemEdit}
      onNewPhotoSelected={onNewPhotoSelected}
      urls={urls}
      isCropping={isCropping}
      aspectRatio={variant.aspectRatio}
      confirmCrop={onCropConfirmed}
      openCropDialog={() => {
        if (urls.original) {
          initCrop(urls.original)
        }
      }}
      closeCropDialog={closeCropDialog}
      next={onNext}
      initialVariantId={variant.variantId}
      variants={variants}
      previous={fullReset}
    />
  )
}
