import { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { DesignTemplate, Cell } from '../../../clients/fagl-server/types'
import Template from '../select-template/Template'
import Button from '../../../shared/buttons/Button'
import CropIcon from '../../../assets/crop.svg?react'
import ReplacePhotoIcon from '../../../assets/replace-photo.svg?react'
import { BottomPanel } from '../../../shared/BottomPanel'
import PhotoSelector from '../components/PhotoSelector'
import useCrop from '../hooks/useCrop'
import CropDialog from '../components/CropDialog'
import html2canvas from 'html2canvas-pro'
import DialogCloseButton from '../../../shared/DialogCloseButton'

type SourceImage = {
  original: string
  cropped: string
}

type CellEditState = {
  cellDefinition: Cell
  photoUrl: string | null
  replacementPhotoUrl: string | null
}

type CellsEditMap = Record<string, CellEditState>

function createInitialCellEditState(cells: Cell[], sourceImages: SourceImage[]) {
  return cells.reduce((acc, cell, index) => {
    const image = sourceImages[index]
    acc[cell.id] = {
      cellDefinition: cell,
      photoUrl: image?.original || null,
      replacementPhotoUrl: image?.cropped || null,
    }
    return acc
  }, {} as CellsEditMap)
}

export default function EditTemplatePage({
  template,
  sourceImages,
  previous,
  next,
  templateWidth,
  templateHeight,
}: {
  sourceImages: SourceImage[]
  template: DesignTemplate
  previous: () => void
  next: (dataUrl: string, sourceImageDataUrls: SourceImage[]) => void
  templateWidth: number
  templateHeight: number
}) {
  const [isProcessing, setIsProcessing] = useState(false)
  const [cellState, setCellState] = useState<CellsEditMap>(
    createInitialCellEditState(template.cells, sourceImages)
  )

  const [editedCellId, setEditedCellId] = useState<number | null>(null)

  const cellsList = Object.values(cellState)
  const editedCell = editedCellId ? cellState[editedCellId] : null

  const [isChangingPhoto, setIsChangingPhoto] = useState(false)

  const { initCrop, isCropping, setIsCropping, confirmCrop, urls } = useCrop({
    original: null,
    cropped: null,
  })

  const onCellClick = (cellId: number) => {
    setEditedCellId(cellId)
  }

  const onConfirmCrop = useCallback(
    (cropped: string) => {
      if (!editedCellId) {
        return
      }
      confirmCrop(cropped)
      setCellState((prev) => {
        return {
          ...prev,
          [editedCellId]: {
            ...prev[editedCellId],
            replacementPhotoUrl: cropped,
          },
        }
      })

      setEditedCellId(null)
      setIsCropping(false)
      setIsChangingPhoto(false)
    },
    [confirmCrop, editedCellId, setCellState]
  )

  const onNewPhotosSelected = useCallback(
    (newPhotoDataurl: string[]) => {
      if (!editedCell) {
        const newCellState = createInitialCellEditState(
          template.cells,
          newPhotoDataurl.map((url) => ({
            original: url,
            cropped: url,
          }))
        )
        setCellState(newCellState)
      } else {
        initCrop(newPhotoDataurl[0])
        setIsCropping(true)
      }
    },
    [editedCell, initCrop, setIsCropping, template.cells]
  )

  const onPrevious = useCallback(() => {
    previous()
  }, [previous])

  const sourceImageDataUrls = Object.values(cellState).map((cell) => ({
    original: cell.photoUrl ?? '',
    cropped: cell.replacementPhotoUrl ?? '',
  }))

  const onNext = useCallback(async () => {
    setIsProcessing(true)
    const canvas = await html2canvas(document.querySelector('#template')!, {
      allowTaint: true,
    })

    setIsProcessing(false)
    next(canvas.toDataURL(), sourceImageDataUrls)
  }, [next, setIsProcessing, sourceImageDataUrls])

  useEffect(() => {
    setCellState(createInitialCellEditState(template.cells, sourceImages))
  }, [sourceImages])

  const areAllCellsFilledWithUrls = cellsList.every(
    (cell) => cell.replacementPhotoUrl || cell.photoUrl
  )

  const title = areAllCellsFilledWithUrls
    ? 'Tap a photo to swap or edit'
    : `Select ${cellsList.length} photos`

  const renderOnCell = useCallback(
    (props: { cell: Cell; photoUrl: string | null; style: React.CSSProperties; index: number }) => {
      const { cell, photoUrl, style, index } = props
      return photoUrl ? (
        <button
          className={cx('w-full h-full peer', {
            selected: editedCellId === cell.id,
          })}
          style={style}
          onClick={() => onCellClick(cell.id)}
        />
      ) : (
        <div
          style={style}
          className={cx('w-full h-full peer bg-[#E5E5E5] flex items-center justify-center', {
            selected: editedCellId === cell.id,
          })}
        >
          <div className="bg-white w-2/5 h-2/5 rounded-full flex items-center justify-center text-gray-9 font-bold">
            {index}
          </div>
        </div>
      )
    },
    [editedCellId]
  )

  const photos = cellsList.map((cell) => cell.replacementPhotoUrl || cell.photoUrl)
  return (
    <div className="h-screen bg-gray-2 relative">
      <div className="p-4 space-y-4">
        <h2 className="text-lg font-bold py-4">{title}</h2>
        <div className="space-y-2">
          <div id="template">
            <Template
              templateWidth={templateWidth}
              templateHeight={templateHeight}
              designTemplate={template}
              photos={photos.filter((photo) => photo !== null) as string[]}
              renderOnCell={renderOnCell}
            />
          </div>
          {editedCell && (
            <div className="bg-gray-12 text-white p-2 rounded-lg text-center">
              ↑ Tap a different photo to swap
            </div>
          )}
        </div>
        {editedCell && (
          <div className="space-y-4">
            <div className="text-center font-bold">OR</div>
            <div className="space-y-2">
              <Button
                onClick={() => {
                  if (editedCell.photoUrl) {
                    initCrop(editedCell.photoUrl)
                    setIsCropping(true)
                  }
                }}
                colorVariant="primary:invert"
                className="w-full flex items-center justify-center"
              >
                <CropIcon className="w-5 mr-1" /> Crop this photo
              </Button>
              <Button
                colorVariant="primary:invert"
                onClick={() => {
                  setIsChangingPhoto(true)
                }}
                className="w-full flex items-center justify-center"
              >
                <ReplacePhotoIcon className="w-5 mr-1 relative top-0.5" /> Change this photo
              </Button>
            </div>
          </div>
        )}
        {!areAllCellsFilledWithUrls && (
          <PhotoSelector
            numberOfPhotos={cellsList.length}
            verticalLayout
            familyAlbumButtonVariant="primary"
            onPhotoSelected={onNewPhotosSelected}
          />
        )}
        <CropDialog
          url={urls.original}
          aspect={
            editedCell?.cellDefinition.width && editedCell?.cellDefinition.height
              ? editedCell.cellDefinition.width / editedCell.cellDefinition.height
              : 1
          }
          isOpen={isCropping}
          confirm={onConfirmCrop}
          close={() => {
            setIsCropping(false)
          }}
        />
        <BottomPanel
          isOverlayOpen={isChangingPhoto}
          close={() => {
            setIsChangingPhoto(false)
          }}
        >
          <div className="bg-white px-4 pb-8 pt-4 rounded-t-lg w-screen">
            <div>
              <DialogCloseButton onClick={() => setIsChangingPhoto(false)} />
            </div>
            <h3 className="font-bold text-center mb-4">Select a photo</h3>
            <div className="grid grid-col-1 gap-2">
              <PhotoSelector
                verticalLayout
                familyAlbumButtonVariant="primary"
                onPhotoSelected={onNewPhotosSelected}
              />
            </div>
          </div>
        </BottomPanel>
      </div>
      <footer className="text-center space-y-4 p-2 bg-white sticky w-full top-full">
        <div className="grid grid-cols-2 gap-2">
          <Button disabled={isProcessing} onClick={onPrevious} colorVariant="primary:invert">
            Back
          </Button>
          <Button
            isLoading={isProcessing}
            disabled={isProcessing}
            onClick={onNext}
            colorVariant="primary"
          >
            Preview
          </Button>
        </div>
      </footer>
    </div>
  )
}
