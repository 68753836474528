export * from './printify'
export * from './photoProductsCart'

export type CuratedPhoto = {
  height: number
  tookAt: string
  url: string
  uuid: string
  width: number
}

export type CropDefinition = {
  x: number
  y: number
  width: number
  height: number
}

export enum VariantCellPositionRelativeToTemplate {
  Beneath = 'BENEATH',
  Above = 'ABOVE',
}

export enum CellType {
  Image = 'IMAGE',
}

export type Cell = {
  id: number
  absoluteCenterX: number
  absoluteCenterY: number
  width: number
  height: number
  order: number
  zIndex: number
  positionRelativeToTemplate: VariantCellPositionRelativeToTemplate
  type: CellType.Image
  VariantCellImage: { id: number; rotationAngle: number }
  templateVariantId: number
  repeat: {
    rows: number
    columns: number
    usedRows: number[]
    usedColumns: number[]
    offsetX: number
    offsetY: number
    isStaggered: boolean
  } | null
}

export type DesignTemplate = {
  id: number
  productVariantIds: number[]
  maskImageUrl: string
  numberOfUserImages: number
  cells: Cell[]
}
