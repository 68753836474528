import { useMemo } from 'react'
import {
  Variant,
  VariantSizeOption,
  VariantColorOption,
} from '../../../clients/fagl-server/types/printify'
import VariantSelector from './VariantSelector'

function reduceVariantsToSizeOptions(variants: Variant[]) {
  return variants.reduce((acc, variant) => {
    const sizeOption = variant.options.find((option) => option.type === 'SIZE')
    if (sizeOption && !acc.find((o) => o.option.value === sizeOption.value)) {
      return [...acc, { variantId: variant.variantId, option: sizeOption as VariantSizeOption }]
    }
    return acc
  }, [] as { variantId: number; option: VariantSizeOption }[])
}

function reduceVariantsToColorOptions(variants: Variant[]) {
  return variants.reduce((acc, variant) => {
    const colorOption = variant.options.find(
      (option) => option.type === 'COLOR'
    )
    if (colorOption && !acc.find((o) => o.option.value === colorOption.value)) {
      return [...acc, { variantId: variant.variantId, option: colorOption as VariantColorOption }]
    }
    return acc
  }, [] as { variantId: number; option: VariantColorOption }[])
}

export default function VariantSelectionSection({
  variants,
  selectedVariantId,
  updateVariantId,
}: {
  variants: Variant[]
  selectedVariantId: number
  updateVariantId: (variantId: number) => void
}) {
  const colorOptions = useMemo(() => {
    return reduceVariantsToColorOptions(variants)
  }, [variants])

  const sizeOptions = useMemo(() => {
    return reduceVariantsToSizeOptions(variants)
  }, [variants])

  const hasVariantsWithColors = colorOptions.filter(Boolean).length > 1
  const hasVariantsWithSizes = sizeOptions.filter(Boolean).length > 1

  const selectedVariant = variants.find(
    (variant) => variant.variantId === selectedVariantId
  )

  const colorName = selectedVariant?.options.find(
    (option) => option.type === 'COLOR'
  )?.value

  return (
    <>
      {hasVariantsWithColors && (
        <VariantSelector
          title="Choose Color"
          option="color"
          selectedVariantId={selectedVariantId}
          updateVariantId={updateVariantId}
          additionalInfo={colorName}
          colorOptions={colorOptions}
          sizeOptions={sizeOptions}
        />
      )}
      {hasVariantsWithSizes && (
        <VariantSelector
          title="Choose Size"
          option="size"
          selectedVariantId={selectedVariantId}
          updateVariantId={updateVariantId}
          colorOptions={colorOptions}
          sizeOptions={sizeOptions}
        />
      )}
    </>
  )
}
