import { CropDefinition } from '../../../clients/fagl-server/types'

/**
 * Calculates the absolute crop values based on the provided crop definition,
 * width, and height.
 *
 * @param crop - The crop definition object.
 * @param width - The width of the media file.
 * @param height - The height of the media file.
 * @returns An object containing the absolute crop values.
 */
export function getAbsoluteCrop(
  crop: CropDefinition,
  width: number,
  height: number
) {
  return {
    x: crop.x * width,
    y: crop.y * height,
    width: crop.width * width,
    height: crop.height * height,
  }
}

export function correctCroppingRect(
  input: CropDefinition,
  targetSize: {
    width: number
    height: number
  }
) {
  const inputRatio = input.width / input.height
  const targetRatio = targetSize.width / targetSize.height

  if (inputRatio === targetRatio) {
    return input
  } else if (inputRatio > targetRatio) {
    let correctedWidth = input.height * targetRatio
    let difference = input.width - correctedWidth
    let correctedX = input.x + difference / 2
    return {
      x: correctedX,
      y: input.y,
      width: correctedWidth,
      height: input.height,
    }
  } else {
    let correctedHeight = input.width / targetRatio
    let difference = input.height - correctedHeight
    let correctedY = input.y + difference / 2
    return {
      x: input.x,
      y: correctedY,
      width: input.width,
      height: correctedHeight,
    }
  }
}

export const getImageDimensions = (
  image: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = image
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
  })
}
