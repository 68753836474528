import React, { useEffect } from 'react'
import { Blueprint, Variant } from '../../../clients/fagl-server/types/printify'
import { useProduct } from '../hooks/useProduct'
import LoadingOverlay from './LoadingOverlay'
import { useParams } from 'react-router-dom'

export const useProductId = () => {
  const { productId } = useParams()

  if (!productId) {
    throw new Error('No product id provided')
  }

  return parseInt(productId, 10)
}

export default function ProductWrapper({
  children,
}: {
  children: ({
    product,
    variants,
    productImages,
  }: {
    product: Blueprint
    variants: Variant[]
    productImages: string[]
    carouselImages: string[]
  }) => React.ReactElement
}) {
  const productId = useProductId()

  const {
    product,
    isLoading: isProductLoading,
    loadError: productLoadError,
    variants,
    load,
    productImages,
    carouselImages,
  } = useProduct()

  useEffect(() => {
    load(productId)
  }, [productId])

  if (isProductLoading) {
    return <LoadingOverlay>Loading product information</LoadingOverlay>
  }

  if (!product) {
    if (productLoadError === 'NOT_FOUND') {
      throw new Error('Product not found')
    }

    throw new Error('Something went wrong')
  }

  return children({ product, variants, productImages, carouselImages })
}
