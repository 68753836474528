import { Carousel, CarouselItem } from '../../../shared/Carousel'

export default function ProductPageUniqueTemplates({
  carouselImages,
  onNextClick,
}: {
  carouselImages: string[]
  onNextClick: () => void
}) {
  return (
    <section className="bg-amber-2 p-4 space-y-4 flex flex-col">
      <h3 className="font-bold text-lg">Unique Templates</h3>
      <Carousel
        scrollContainerClassName="space-x-4"
        items={carouselImages.map((src, index) => ({ src, id: index }))}
        renderItem={({ item, isSnapPoint }) => (
          <CarouselItem key={item.id} isSnapPoint={isSnapPoint}>
            <img src={item.src} className="rounded-lg" width="160" height="160" alt="" />
          </CarouselItem>
        )}
      />
      <button onClick={onNextClick} className="font-bold self-end text-mi-primary block text-right">
        See more design options
      </button>
    </section>
  )
}
