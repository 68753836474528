import { useCallback, useState } from 'react'
import { CuratedPhoto } from '../../../clients/fagl-server/types'
import { useApi } from '../../../hooks/useApi'
import { useRollbar } from '@rollbar/react'

const FALLBACK_PHOTOS = [
  {
    url: '/public/curated-fallback/1.jpeg',
    uuid: '1',
    height: 537,
    width: 358,
    tookAt: '2024-10-31T13:49:12.620Z',
  },
  {
    url: '/public/curated-fallback/2.jpeg',
    uuid: '2',
    height: 544,
    width: 356,
    tookAt: '2024-10-31T13:49:12.620Z',
  },
  {
    url: '/public/curated-fallback/3.jpeg',
    uuid: '3',
    height: 473,
    width: 315,
    tookAt: '2024-10-31T13:49:12.620Z',
  },
  {
    url: '/public/curated-fallback/4.jpeg',
    uuid: '4',
    height: 516,
    width: 362,
    tookAt: '2024-10-31T13:49:12.620Z',
  },
  {
    url: '/public/curated-fallback/5.jpeg',
    uuid: '5',
    height: 556,
    width: 371,
    tookAt: '2024-10-31T13:49:12.620Z',
  },
  {
    url: '/public/curated-fallback/6.jpeg',
    uuid: '6',
    height: 556,
    width: 371,
    tookAt: '2024-10-31T13:49:12.620Z',
  },
]

export default function useCuratedPhotos() {
  const { api } = useApi()
  const rollbar = useRollbar()
  const [curatedPhotos, setCuratedPhotos] = useState<CuratedPhoto[]>([])
  const [arePhotosLoaded, setArePhotosLoaded] = useState(false)
  const [isUsingFallback, setIsUsingFallback] = useState(false)
  const getCuratedPhotos = useCallback(async () => {
    try {
      const photos = await api.getFamilyAlbumCuratedPhotos()
      if (photos.length === 0) {
        throw new Error('0 curated photos found')
      }

      const base64 = await api.convertCdnPhotosToBase64(
        photos.map((p) => ({ url: p.url, uuid: p.uuid }))
      )

      setCuratedPhotos(
        photos.map((photo, index) => ({
          ...photo,
          url: base64[index].url,
        }))
      )
    } catch (error) {
      setCuratedPhotos(FALLBACK_PHOTOS)
      setIsUsingFallback(true)
      rollbar.error('Error loading curated photos', error as Error)
    } finally {
      setArePhotosLoaded(true)
    }
  }, [api])

  return {
    curatedPhotos,
    arePhotosLoaded,
    isUsingFallback,
    getCuratedPhotos,
  }
}
