import React, { useCallback, useEffect, useState } from 'react'
import {
  Cell,
  CropDefinition,
  DesignTemplate,
  VariantCellPositionRelativeToTemplate,
} from '../../../clients/fagl-server/types'
import { correctCroppingRect, getAbsoluteCrop, getImageDimensions } from './utils'
import { CheckIcon } from '@heroicons/react/24/solid'

type RenderOnCell = (props: {
  cell: Cell
  photoUrl: string | null
  style: React.CSSProperties
  index: number
}) => React.ReactNode

type CellDimensions = { x: number; y: number; width: number; height: number }

function getSlots({ cell, baseCellValues }: { cell: Cell; baseCellValues: CellDimensions }) {
  const { repeat } = cell
  if (!repeat) {
    return [
      {
        x: baseCellValues.x,
        y: baseCellValues.y,
      },
    ]
  }

  const { x, y, width, height } = baseCellValues

  const {
    rows,
    columns,
    // usedRows,
    // usedColumns,
    // offsetX,
    // offsetY,
    isStaggered,
  } = repeat

  const offsetX = width
  const offsetY = height

  let positions = []

  for (let row = 0; row < rows; row++) {
    for (let column = 0; column < columns; column++) {
      if (row % 2 === 0) {
        if (column % 2 === 0) {
          positions.push({ x: x + column * offsetX, y: y + row * offsetY })
        }
      } else {
        if (column % 2 === Number(isStaggered)) {
          positions.push({
            x: x + column * offsetX,
            y: y + row * offsetY,
          })
        }
      }
    }
  }

  return positions
}

function getRelativeStyle(
  { x, y, width, height }: CellDimensions,
  templateWidth: number,
  templateHeight: number
) {
  return {
    left: `${(x / templateWidth) * 100}%`,
    top: `${(y / templateHeight) * 100}%`,
    width: `${(width / templateWidth) * 100}%`,
    height: `${(height / templateHeight) * 100}%`,
  }
}

const CellComponent = ({
  coordinates,
  index,
  templateWidth,
  templateHeight,
  cellZIndex,
  baseCellValues,
  photoUrl,
  renderOnCell,
  cell,
}: {
  coordinates: { x: number; y: number }
  index: number
  templateWidth: number
  templateHeight: number
  cellZIndex: number
  baseCellValues: CellDimensions
  photoUrl: string | null
  renderOnCell?: RenderOnCell
  cell: Cell
}) => {
  const containerStyle = {
    ...getRelativeStyle(
      {
        ...baseCellValues,
        ...coordinates,
      },
      templateWidth,
      templateHeight
    ),
  }
  return (
    <>
      {renderOnCell &&
        renderOnCell({
          cell,
          photoUrl,
          index,
          style: {
            ...containerStyle,
            position: 'absolute',
            zIndex: cellZIndex + 2,
          },
        })}

      <div
        style={{
          ...containerStyle,
          zIndex: cellZIndex + 1,
        }}
        className="absolute inset-0 bg-black/50 opacity-0 peer-[.selected]:opacity-100 transition-opacity flex items-center justify-center"
      >
        <CheckIcon className="w-2/3 h-2/3 text-white" />
      </div>

      {photoUrl && (
        <div
          className="absolute"
          style={{
            ...containerStyle,
            zIndex: cellZIndex,
          }}
        >
          <img className="w-full h-full object-cover" src={photoUrl} alt="photo" />
        </div>
      )}
    </>
  )
}

function TemplateCells({
  index,
  cell,
  templateWidth,
  templateHeight,
  renderOnCell,
  photoUrl,
  backgroundZIndex,
}: {
  index: number
  cell: Cell
  templateWidth: number
  templateHeight: number
  renderOnCell?: RenderOnCell
  photoUrl: string | null
  backgroundZIndex: number
}) {
  const { positionRelativeToTemplate, repeat, width, height, absoluteCenterX, absoluteCenterY } =
    cell

  const baseCellValues = {
    x: absoluteCenterX - width / 2,
    y: absoluteCenterY - height / 2,
    width: width,
    height: height,
  }

  const cellZIndex =
    positionRelativeToTemplate === VariantCellPositionRelativeToTemplate.Above
      ? backgroundZIndex + 2
      : backgroundZIndex - 2

  const slots = getSlots({
    cell,
    baseCellValues,
  }).map((slot, slotIndex) => {
    return (
      <CellComponent
        cell={cell}
        key={index + '-' + slotIndex}
        index={index + 1}
        coordinates={slot}
        templateWidth={templateWidth}
        templateHeight={templateHeight}
        cellZIndex={cellZIndex}
        baseCellValues={baseCellValues}
        photoUrl={photoUrl}
        renderOnCell={renderOnCell}
      />
    )
  })

  return <div key={cell.id}>{slots}</div>
}

export default function Template({
  photos,
  renderOnCell,
  designTemplate: { maskImageUrl, cells },
  templateWidth,
  templateHeight,
}: {
  photos: string[]
  renderOnCell?: RenderOnCell
  designTemplate: DesignTemplate
  templateWidth: number
  templateHeight: number
}) {
  const templateUrl = `/templates/${maskImageUrl}`

  const [photosState, setPhotosState] = useState<
    {
      url: string
      width: number
      height: number
      crop: CropDefinition
    }[]
  >([])

  const getDimensions = useCallback(
    async (photos: string[]) => {
      const images = await Promise.all(
        photos.map(async (url) => {
          const { width, height } = await getImageDimensions(url)
          return {
            url,
            width: width,
            height: height,
            crop: {
              x: 0,
              y: 0,
              width: 1,
              height: 1,
            },
          }
        })
      )

      return images
    },
    [templateUrl]
  )

  const init = useCallback(async () => {
    const dimensions = await getDimensions(photos)
    setPhotosState(dimensions)
  }, [templateUrl, getDimensions, photos])

  useEffect(() => {
    init()
  }, [init])

  const cellsToRender = cells.map((cell, index) => {
    const photoForCell = photosState[index]
    const photoCrop = photoForCell
      ? correctCroppingRect(
          getAbsoluteCrop(photoForCell.crop, photoForCell.width, photoForCell.height),
          {
            width: cell.width,
            height: cell.height,
          }
        )
      : null
    return {
      ...cell,
      photoUrl: photosState[index]?.url || null,
      photoCrop: photoCrop,
    }
  })

  const backgroundZIndex = 10

  return (
    <div id="template" className="relative">
      <img
        src={templateUrl}
        style={{
          zIndex: backgroundZIndex,
        }}
        alt="template"
        className="w-full h-full object-cover relative"
      />
      {cellsToRender.map((cell, index) => (
        <TemplateCells
          index={index}
          key={cell.id}
          cell={cell}
          templateWidth={templateWidth}
          templateHeight={templateHeight}
          renderOnCell={renderOnCell}
          photoUrl={cell.photoUrl}
          backgroundZIndex={backgroundZIndex}
        />
      ))}
    </div>
  )
}
