import React from 'react'
import FaAppLogo from '../../../assets/fa-app-logo.svg?react'

export default function GroupHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-gray-2 text-sm text-gray-12 items-center flex px-4 py-2 text-left">
      <FaAppLogo className="h-7 w-7" />
      <span className="ml-2">{children}</span>
    </div>
  )
}
