import { Fragment } from 'react'
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from '@headlessui/react'

const overlayTransition = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
}

const panelTransition = {
  enter: 'ease-out duration-300',
  enterFrom: 'translate-y-20',
  enterTo: 'translate-y-0',
  leave: 'ease-in duration-200',
  leaveFrom: 'translate-y-0',
  leaveTo: 'translate-y-20',
}

export function BottomPanel({
  isOverlayOpen,
  children,
  close,
}: {
  isOverlayOpen: boolean
  close: Function
  children: React.ReactNode
}) {
  return (
    <Transition show={isOverlayOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => close()}>
        <TransitionChild as={Fragment} {...overlayTransition}>
          <div className="fixed inset-0 bg-fa-dark bg-opacity-50" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto flex justify-end items-end">
          <TransitionChild as={Fragment} {...panelTransition}>
            <DialogPanel>{children}</DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  )
}
