import { ChangeEventHandler, useCallback, useState } from 'react'
import cx from 'classnames'
import Button from '../../../shared/buttons/Button'
import FamilyAlbumPhotosSelectionPanel from '../FamilyAlbumPhotoSelectionPanel'
import PhotoUploadButton from '../FamilyAlbumPhotoSelectionPanel/PhotoUploadButton'
import useFamilyAlbumUserAgent from '../../../hooks/useFamilyAlbumUserAgent'
import { convertFileToBase64 } from '../../../utils'

export default function PhotoSelector({
  onPhotoSelected,
  verticalLayout = false,
  familyAlbumButtonVariant = 'primary:invert',
  numberOfPhotos = 1,
}: {
  onPhotoSelected: (base64: string[]) => void
  verticalLayout?: boolean
  familyAlbumButtonVariant?: 'primary' | 'primary:invert'
  numberOfPhotos?: number
}) {
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  const openFamilyAlbumPanel = useCallback(() => {
    setIsPanelOpen(true)
  }, [])

  const closeFamilyAlbumPanel = useCallback(() => {
    setIsPanelOpen(false)
  }, [])

  const onPhotoSelectedFromFamilyAlbum = useCallback(
    async (photoUrls: string[]) => {
      return onPhotoSelected(photoUrls)
    },
    [onPhotoSelected]
  )

  const onPhotoSelectedFromDevice: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      const files = Array.from(event.target.files ?? [])
      if (!files || !files[0]) {
        return
      }

      const filesToUse = files.slice(0, numberOfPhotos)

      const base64Originals = await Promise.all(filesToUse.map(convertFileToBase64))
      return onPhotoSelected(base64Originals)
    },
    [onPhotoSelected, numberOfPhotos]
  )

  const { appVersionSupportsUpload } = useFamilyAlbumUserAgent()
  const showDeviceUpload = appVersionSupportsUpload

  return (
    <>
      <div
        className={cx('grid gap-2', {
          'grid-cols-1': !showDeviceUpload || verticalLayout,
          'grid-cols-2': showDeviceUpload && !verticalLayout,
        })}
      >
        <Button
          colorVariant={familyAlbumButtonVariant}
          className="text-s"
          onClick={openFamilyAlbumPanel}
        >
          From FamilyAlbum
        </Button>
        {showDeviceUpload && (
          <PhotoUploadButton
            multiple={numberOfPhotos > 1}
            colorVariant="primary:invert"
            className="text-s py-2.5"
            onFilesSelected={onPhotoSelectedFromDevice}
          >
            From Device
          </PhotoUploadButton>
        )}
      </div>
      <FamilyAlbumPhotosSelectionPanel
        isOpen={isPanelOpen}
        onClose={closeFamilyAlbumPanel}
        next={onPhotoSelectedFromFamilyAlbum}
        numberOfPhotos={numberOfPhotos}
      />
    </>
  )
}
