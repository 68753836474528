import {
  VariantColorOption,
  VariantSizeOption,
} from '../../../clients/fagl-server/types/printify'
import VariantSelectButton from './VariantSelectButton'
import VariantSizeSelector from './VariantSizeSelector'

export default function VariantSelector({
  title,
  option,
  selectedVariantId,
  updateVariantId,
  additionalInfo,
  colorOptions,
  sizeOptions,
}: {
  title: string
  option: 'size' | 'color'
  selectedVariantId: number
  updateVariantId: (variantId: number) => void
  additionalInfo?: string
  colorOptions: {
    variantId: number
    option: VariantColorOption
  }[]
  sizeOptions: {
    variantId: number
    option: VariantSizeOption
  }[]
}) {
  return (
    <div className="space-x-1 space-y-2">
      <div className="flex items-baseline">
        <h2 className="font-bold">{title}</h2>
        {additionalInfo && (
          <span className="text-gray-11 text-s ml-1">({additionalInfo})</span>
        )}
      </div>
      {option === 'size' && (
        <VariantSizeSelector
          sizeOptions={sizeOptions}
          updateVariantId={updateVariantId}
          selectedVariantId={selectedVariantId}
        />
      )}
      {option === 'color' && (
        <ul className="flex flex-wrap gap-3">
          {colorOptions.map(({ variantId, option: { value, hex } }) => {
            const isSelected = selectedVariantId === variantId
            return (
              <li key={variantId}>
                <VariantSelectButton
                  isSelected={isSelected}
                  colorName={value}
                  colorValue={hex}
                  variantId={variantId}
                  updateVariantId={updateVariantId}
                />
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
