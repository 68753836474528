import { VariantSizeOption } from '../../../clients/fagl-server/types/printify'

export default function VariantSizeSelector({
  updateVariantId,
  selectedVariantId,
  sizeOptions,
}: {
  updateVariantId: (variantId: number) => void
  selectedVariantId: number
  sizeOptions: {
    variantId: number
    option: VariantSizeOption
  }[]
}) {
  return (
    <div className="flex flex-row gap-6">
      <span>Pcs:</span>
      <select
        className="border border-solid p-1 transition-all border-fa-border"
        value={selectedVariantId || sizeOptions[0].variantId}
        onChange={(e) => updateVariantId(Number(e.target.value))}
      >
        {sizeOptions.map(({ variantId, option: { value } }) => (
          <option key={variantId} value={variantId}>
            {value}
          </option>
        ))}
      </select>
    </div>
  )
}
