import { MouseEventHandler } from 'react'
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/solid'

export default function Header({
  onClose,
  title,
  onBack,
}: {
  title: string
  onClose: MouseEventHandler
  onBack?: MouseEventHandler
}) {
  return (
    <div className="bg-white border-b py-6 flex items-center justify-center relative">
      {onBack && (
        <button onClick={onBack} className="absolute left-4">
          <ChevronLeftIcon className="w-6 text-fa-primary" />
        </button>
      )}
      <h3 className="text-sm font-bold">{title}</h3>

      <button onClick={onClose} className="absolute right-4">
        <XMarkIcon className="w-6" />
      </button>
    </div>
  )
}
