import Button from '../../../shared/buttons/Button'

export default function ProductPageFooter({
  openTermsOfUsePage,
  previous,
  onNextClick,
}: {
  openTermsOfUsePage: () => void
  previous: () => void
  onNextClick: () => void
}) {
  return (
    <footer className="sticky top-0 bottom-0">
      <div className="text-center space-y-4 p-2 bg-white shadow-footer">
        <p className="text-gray-11">
          By continuing, you agree to our{' '}
          <button className="text-blue-10" onClick={openTermsOfUsePage}>
            Terms of Service
          </button>
        </p>
        <div className="grid grid-cols-2 gap-2">
          <Button onClick={previous} colorVariant="primary:invert">
            Back
          </Button>
          <Button onClick={onNextClick} colorVariant="primary">
            Personalize
          </Button>
        </div>
      </div>
    </footer>
  )
}
