import { useEffect, useState } from 'react'
import cx from 'classnames'
import Spinner from '../../../shared/Spinner'
import { PreviewUrlState } from '../hooks/usePreview'

export default function ProductPhotos({
  photos,
}: {
  photos: PreviewUrlState[]
}) {
  const [selectedPreviewIndex, setSelectedPreviewIndex] = useState(0)
  useEffect(() => {
    const defaultIndex = photos.findIndex((photo) => photo.isDefault)

    setSelectedPreviewIndex(defaultIndex)
  }, [photos])

  const mainPreview = photos[selectedPreviewIndex]

  return (
    <div className="space-y-4">
      <div className="w-full rounded-lg relative">
        <img
          className={cx('rounded-lg w-full', {
            'blur-sm': !mainPreview?.isPreviewReady && !mainPreview?.base64,
          })}
          key={mainPreview?.url + mainPreview?.isPreviewReady}
          src={mainPreview?.base64}
        />
        {(!mainPreview?.isPreviewReady && !mainPreview?.possiblySucceededOnFirstLoad) && (
          <div className="absolute flex top-0 left-0 w-full h-full items-center justify-center">
            <Spinner className="w-8" />
          </div>
        )}
      </div>
      <ul className="grid grid-cols-4 gap-2">
        {photos.map((photo, index) => (
          <li key={photo.url + photo.isPreviewReady}>
            <button
              className={cx(
                'rounded-lg ring-fa-primary relative transition-all',
                {
                  'ring-2': selectedPreviewIndex === index,
                  'ring-0': selectedPreviewIndex !== index,
                }
              )}
              onClick={() => setSelectedPreviewIndex(index)}
            >
              <img
                className={cx('rounded-lg', {
                  'blur-sm': !photo.isPreviewReady && !photo.base64,
                })}
                src={photo.base64}
              />
              {(!photo.isPreviewReady && !photo.possiblySucceededOnFirstLoad) && (
                <div className="absolute flex top-0 left-0 w-full h-full items-center justify-center">
                  <Spinner className="w-6" />
                </div>
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
