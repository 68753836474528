import { Link } from 'react-router-dom'
import { CuratedPhoto, DesignTemplate } from '../../../clients/fagl-server/types'
import ShoppingCartBadge from '../components/ShoppingCartBadge'
import Template from './Template'
import Button from '../../../shared/buttons/Button'
import StickyFooter from '../../../shared/StickyFooter'

export default function SelectTemplatePage({
  selectTemplate,
  curatedPhotos,
  arePhotosLoaded,
  designTemplates,
  templateWidth,
  templateHeight,
  numberOfItemsInCart,
  onShoppingCartClick,
  previous,
}: {
  selectTemplate: (template: DesignTemplate) => void
  curatedPhotos: CuratedPhoto[]
  arePhotosLoaded: boolean
  designTemplates: DesignTemplate[]
  templateWidth: number
  templateHeight: number
  numberOfItemsInCart: number
  onShoppingCartClick: () => void
  previous: () => void
}) {
  if (!arePhotosLoaded) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div className="mx-auto max-w-screen-lg">
        <header className="p-4 pt-8 text-black">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Select a design</h2>
            <Link to="/photo-products/cart" onClick={onShoppingCartClick}>
              <ShoppingCartBadge numberOfItemsInCart={numberOfItemsInCart} />
            </Link>
          </div>
        </header>
        <ul className="p-4 grid grid-cols-2 gap-4 gap-y-6">
          {designTemplates
            .filter((dt) => dt.numberOfUserImages <= curatedPhotos.length)
            .map((dt) => {
              return (
                <li key={dt.id}>
                  <button onClick={() => selectTemplate(dt)}>
                    <Template
                      templateWidth={templateWidth}
                      templateHeight={templateHeight}
                      designTemplate={dt}
                      photos={curatedPhotos.map((photo) => photo.url)}
                    />
                  </button>
                </li>
              )
            })}
        </ul>
      </div>
      <StickyFooter className="z-10">
        <Button onClick={previous} className="w-full" colorVariant="primary">
          Back
        </Button>
      </StickyFooter>
    </>
  )
}
