import { z } from 'zod'

export enum Provider {
  PRINTIFY = 'PRINTIFY',
  DIVVYUP = 'DIVVYUP', // we are not planning to support them for now as a provider, but for tracking purposes we need to have it
}

const usedImageSchema = z.object({
  cropped: z.string(),
  original: z.string(),
})

export const printifyMetadataSchema = z.object({
  blueprintId: z.number(),
  variantId: z.number(),
  printProviderId: z.number(),
  title: z.string(),
  price: z.number(),
  previewCdnUrl: z.string(),
  sourceImages: z.array(usedImageSchema),
  designTemplateId: z.number().optional(),
})

export type PrintifyMetadata = z.infer<typeof printifyMetadataSchema>

export const addItemToCartPayloadSchema = z.object({
  itemIdentifier: z.string(),
  provider: z.nativeEnum(Provider),
  metadata: printifyMetadataSchema,
})

export type AddItemToCartPayload = z.infer<typeof addItemToCartPayloadSchema>

export type CartItem = {
  id: number
  cartId: number
  itemIdentifier: string
  provider: 'PRINTIFY'
  quantity: number
  metadata: PrintifyMetadata
  price: number
  salePrice: number
  createdAt: string
  updatedAt: string
  hasColors: boolean
  hasSizes: boolean
}

export type Cart = {
  id: number
  familyAlbumUuid: string
  createdAt: string
  updatedAt: string
  items: CartItem[]
  abandonedAt: string | null
  resumedAt: string | null
}

export type GetCartResponse = Cart
export type AddItemToCartResponse = Cart
export type UpdateItemCartQuantityResponse = Cart
export type AddItemToCart = (payload: AddItemToCartPayload) => Promise<AddItemToCartResponse>

export type UpdateItemCartQuantity = (
  itemId: number,
  payload: {
    quantity: number
  }
) => Promise<UpdateItemCartQuantityResponse>
export type GetCart = () => Promise<GetCartResponse>
export type DeleteCartItem = (itemId: number) => Promise<Cart>

export type CreateCheckoutSession = () => Promise<{
  clientSecret: string
  checkoutSessionId: string
}>

export type GetCheckoutSession = (checkoutSessionId: string) => Promise<{
  amount: number
  email: string
}>

export type AbandonCart = () => Promise<void>
export type ResumeCart = () => Promise<Cart>
