import { useCallback, useState } from 'react'

export default function useCrop(
  initialUrls: {
    original: string | null
    cropped: string | null
  } | null
) {
  const [urls, setUrls] = useState<{
    original: string | null
    cropped: string | null
  }>({
    original: initialUrls?.original ?? null,
    cropped: initialUrls?.cropped ?? null,
  })

  const [isCropping, setIsCropping] = useState(false)

  const init = useCallback((base64: string) => {
    setUrls((u) => {
      return {
        ...u,
        original: base64,
      }
    })
    setIsCropping(true)
  }, [])

  const confirmCrop = useCallback((base64url: string) => {
    setUrls((u) => {
      return {
        ...u,
        cropped: base64url,
      }
    })
    setIsCropping(false)
  }, [])

  const closeCropDialog = useCallback(() => {
    setIsCropping(false)
  }, [setIsCropping])

  const reset = useCallback(async (base64: string) => {
    setUrls((u) => {
      return {
        ...u,
        original: base64,
      }
    })
    setIsCropping(true)
  }, [])

  const fullReset = useCallback(() => {
    setUrls({
      cropped: null,
      original: null,
    })
  }, [])

  return {
    initCrop: init,
    isCropping,
    setIsCropping,
    confirmCrop,
    resetCrop: reset,
    fullReset,
    closeCropDialog,
    urls,
  }
}
