import { useCallback, useState } from 'react'
import { Blueprint, Variant } from '../../../clients/fagl-server/types/printify'
import ProductPhotos from '../components/ProductPhotos'
import QuantitySelector from '../components/QuantitySelector'
import VariantSelectionSection from '../components/VariantSelectionSection'
import ProductPageUniqueTemplates from './ProductPageUniqueTemplates'
import ProductPageFooter from './ProductPageFooter'
import ProductPageDetails from './ProductPageDetailts'
import ProductPageHeader from './ProductPageHeader'

export default function ProductPage({
  next,
  previous,
  product,
  variants,
  initialVariantId,
  initialQuantity,
  price,
  salePrice,
  openTermsOfUsePage,
  updateQuantityAndVariant,
  variantTitle,
  productImages,
  carouselImages,
}: {
  next: (payload: { quantity: number; variantId: number }) => void
  previous: () => void
  product: Blueprint
  variants: Variant[]
  initialVariantId: number
  initialQuantity: number
  price: string
  variantTitle: string
  salePrice: string
  productImages: string[]
  carouselImages: string[]
  openTermsOfUsePage: () => void
  updateQuantityAndVariant: (quantity: number, variantId: number) => void
}) {
  const [quantity, setQuantity] = useState(initialQuantity)
  const [variantId, setVariantId] = useState<number>(initialVariantId)

  const onNextClick = useCallback(() => {
    if (!variantId) {
      return
    }
    next({ quantity, variantId })
  }, [quantity, variantId, next])

  const updateVariantId = useCallback(
    (variantId: number) => {
      setVariantId(variantId)
      updateQuantityAndVariant(quantity, variantId)
    },
    [quantity, updateQuantityAndVariant]
  )

  return (
    <div className="h-screen relative flex flex-col pt-4">
      <main className="flex-1 px-4 pb-8 space-y-4">
        <ProductPhotos
          photos={productImages.map((url, index) => ({
            isDefault: index === 0,
            isPreviewReady: true,
            isDoneTrying: true,
            url,
            base64: url,
            possiblySucceededOnFirstLoad: false,
          }))}
        />
        <ProductPageHeader variantTitle={variantTitle} price={price} salePrice={salePrice} />
        <div className="space-x-1 space-y-2">
          <h2 className="font-bold">Choose Quantity</h2>
          <QuantitySelector quantity={quantity} setQuantity={setQuantity} maxQuantity={10} />
        </div>
        <VariantSelectionSection
          variants={variants}
          selectedVariantId={variantId}
          updateVariantId={updateVariantId}
        />
        <ProductPageDetails itemDetails={product.itemDetails} keyFeatures={product.keyFeatures} />
      </main>
      <ProductPageUniqueTemplates carouselImages={carouselImages} onNextClick={onNextClick} />
      <ProductPageFooter
        openTermsOfUsePage={openTermsOfUsePage}
        previous={previous}
        onNextClick={onNextClick}
      />
    </div>
  )
}
