import { useState } from 'react'
import useImageHashCompare from '../useImageHashCompare'
import { ApiClient } from '../../../ApiClient'

async function getBase64(url: string): Promise<string> {
  try {
    const response = await fetch(url, { method: 'GET' })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const blob = await response.blob()

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result as string)
      }
      reader.onerror = () => {
        reject(new Error('Failed to read the Blob as Base64'))
      }
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to fetch and convert to Base64: ${error.message}`)
    } else {
      throw new Error('Failed to fetch and convert to Base64: Unknown error')
    }
  }
}

async function fetchTimesUntilImageChanged(
  url: string,
  times: number,
  delay = 1000,
  baseImageHashes: string[] = [],
  hashImage: (base64: string) => Promise<string>,
  isImageDifferentFromBaseImages: (hashA: string, baseImageHashes: string[]) => boolean
) {
  let i = 0

  while (i <= times) {
    try {
      const value = await getBase64(url)
      const hash = await hashImage(value)
      const isBaseImage = isImageDifferentFromBaseImages(hash, baseImageHashes)
      if (!isBaseImage) {
        return {
          isDoneTrying: true,
          isPreviewReady: true,
          times: i,
          base64: value,
          possiblySucceededOnFirstLoad: false,
        }
      } else if (i === times) {
        return {
          isDoneTrying: true,
          isPreviewReady: false,
          times: i,
          base64: value,
          possiblySucceededOnFirstLoad: true,
        }
      }
    } catch (err) {
      console.error(err)
    }
    await new Promise((resolve) => setTimeout(resolve, delay))
    i++
  }

  return {
    isDoneTrying: true,
    isPreviewReady: false,
    times: i,
    possiblySucceededOnFirstLoad: true,
  }
}

export type PreviewUrlState = {
  isPreviewReady: boolean
  isDoneTrying: boolean
  isDefault: boolean
  url: string
  base64: string
  possiblySucceededOnFirstLoad: boolean
}

export type PreviewImage = {
  src: string
  is_default: boolean
  initialBase64: string
}
export function usePreview(api: ApiClient, variantId: number | undefined) {
  const [previewUrlState, setPreviewUrlState] = useState<Record<string, PreviewUrlState>>({})
  
  const { baseImageHashes, hashImage, isImageDifferentFromBaseImages } = useImageHashCompare(
    api,
    variantId || undefined
  )

  const fetchPreviewImages = async (images: PreviewImage[]) => {
    const initialStateForProcessedImages = Object.fromEntries(
      images.map((image) => [
        image.src,
        {
          isDoneTrying: false,
          isPreviewReady: false,
          isDefault: image.is_default,
          url: image.src,
          base64: image.initialBase64,
          possiblySucceededOnFirstLoad: false,
        },
      ])
    )

    // Since we are fetching potentially only a subset of the images, we
    // need to merge their fresh state with the existing state.
    setPreviewUrlState((state) => ({
      ...state,
      ...initialStateForProcessedImages,
    }))

    const handleUrl = async (url: string) => {
      const {
        isDoneTrying,
        isPreviewReady,
        base64 = '',
        possiblySucceededOnFirstLoad,
      } = await fetchTimesUntilImageChanged(
        url,
        10,
        1500,
        baseImageHashes,
        hashImage,
        isImageDifferentFromBaseImages
      )

      setPreviewUrlState((prev) => ({
        ...prev,
        [url]: {
          isDoneTrying,
          isPreviewReady,
          isDefault: prev[url].isDefault,
          url,
          base64,
          possiblySucceededOnFirstLoad,
        },
      }))
    }

    const promises = images.map((src) => handleUrl(src.src))
    await Promise.all(promises)
  }

  return {
    fetchPreviewImages,
    previewUrlState,
    setPreviewUrlState,
  }
}
